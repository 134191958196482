@import "../mixins/projects";
@import "../mixins/variables";
@import "../mixins/media";

.other_page {
  .ec-role {
    line-height: 1.6;
  }
  p {
    font-size: 1.4rem;
  }
}

.page_help {
  &_about {
    &__img {
      text-align: center;
      padding-bottom: 30px;

      &__inner {
        display: inline-block;
        position: relative;
        a {
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 25rem;

        }
      }
    }
  }

  &_guide {
    &__linklist {
      list-style: none;
      padding: 0;
      display: flex;
      flex-wrap: wrap;

      li {
        width: 48%;
        margin: 1%;

        @include media_tablet {
          width: 31.33%;
        }

        @include media_desktop {
          width: 23%;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          background: #f6f6f6;
          height: 100%;
          color: #9095AA;
          font-weight: bold;
          padding: 10px;
          transition: $transition-default;
          @include media_desktop {
            &:hover {
              background: #eeeeee;
              text-decoration: none;
            }
          }

        }
      }
    }

    &__guidelist {
      h3 {
        font-size: 1.6rem;
        line-height: 1.7;
        @include media_desktop {
          font-size: 1.8rem;
        }
      }
    }
  }
}

