@import "../mixins/projects";
@import "../mixins/variables";
@import "../mixins/media";

.item-list {
  counter-reset: itemlist;

  &__item {
    box-shadow: 0 1px 2px 3px #f6f6f6;
    border-radius: 5px;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-flow: column;

    &__inner {
      padding: 10px;
    }

    &__bottom {
      padding: 10px;
      margin-top: auto;
    }

    &__image {
      margin: 0;
      position: relative;
    }

    &__soldout {
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(255,255,255,0.6);
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-size: 16px;
        font-weight: bold;
        padding: 10px;
        background-color: #ffffff;
        color: red;
      }


    }

    &__name {
      font-size: 14px;
    }

    &__price01 {
      font-size: 12px;
      margin-top: 2px;
    }

    &__price {
      font-size: 18px;
      margin-top: 6px;
    }

    &__comment {
      font-size: 12px;
      margin-top: 8px;
    }
  }

  a {
    color: #333;
    transition: $transition-default;
    @include media_desktop {
      &:hover {
        opacity: $opacity-default;
        text-decoration: none;
      }
    }
  }

  &__count {
    &__text {
      position: relative;

      &:before {
        position: absolute;
        left: 10px;
        top: 10px;
        display: block;
        width: 30px;
        height: 30px;
        padding: 4px;
        white-space: nowrap;
        background-color: #907A9F;
        color: #fff;
        text-align: center;
        counter-increment: itemlist;
        content: counter(itemlist);
        border-radius: 50%;
      }

    }
  }

}
