@import "../mixins/projects";
@import "../mixins/variables";
@import "../mixins/media";

.side-nav {
  > div + div {
    margin-top: 15px;
    padding-top: 10px;
  }

  &__heading {
    margin-bottom: 10px;
    color: #000;
    font-size: 14px;
    font-weight: bold;
    padding: 5px;
    border-bottom: 3px solid #ebebeb;
  }

  &__banner {
    &__itemlist {
      list-style: none;
      font-size: 0;
      padding: 0;

      li {
        text-align: center;

        + li {
          padding-top: 10px;
        }
        a {
          display: inline-block;
        }
      }
    }
  }
}