@import "../mixins/media";
@import "../mixins/projects";
/*
フッター

全ページで使用されるフッターのプロジェクトコンポーネントです。

ex [トップページ　フッター](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerRole

Styleguide 11.3
*/
.ec-footerRole{
  margin-top: 30px;

  @include media_desktop(){
    margin-top: 100px;
  }

  img {
    width: auto;
    max-width: 100%;
  }

  & &__inner{
    padding-left: 20px;
    padding-right: 20px;
    @include media_desktop {
      @include container;
    }
  }

  &__main {
    background: #EEF0E1;
    padding-top: 20px;

    @include media_desktop(){
      padding-top: 40px;
      padding-bottom: 20px;
    }

    .ec-footerRole__inner {
      @include media_desktop(){
        display: flex;
        flex-wrap: wrap;
      }

    }
  }

  &__nav {
    background: #EEF0E1;
  }

  &__bottom {
    padding: 20px 0;
  }

  &__company {
    width: 100%;

    @include media_desktop(){
      width: 50%;
      padding-right: 20px;
    }

    @include media_desktop2(){
      flex: 1;
      padding-right: 30px;
    }

    &__text {
      padding-top: 20px;
      font-size: 14px;
      line-height: 1.7;
    }

    &__image {
      margin-top: 20px;
      text-align: center;
    }
  }

  &__fb {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 20px 0;
    overflow: hidden;

    @include media_desktop(){
      width: 50%;
      padding: 0;
    }

    @include media_desktop2(){
      width: 500px;
    }

    @include media_desktop(){
      .fb-page {
        > span {
          height: 100% !important;
        }
      }
    }


  }

  &__secure {
    font-size: 12px;
  }
}

/*
フッターナビ

フッタープロジェクトで使用するナビゲーション用のコンポーネントです。

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerNav

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.1
*/
.ec-footerNavi{
  padding: 20px 0 0;
  list-style: none;
  text-align: center;
  margin: 0;

  @include media_desktop {
    padding: 20px 0;
  }

  & &__link{
    display: block;
    &:first-child {
      border-top: 1px solid #fff;
    }

    @include media_desktop {
      display: inline-block;
      &:first-child {
        border: none;
      }
    }

    a{
      display: block;
      border-bottom: 1px solid #fff;
      padding: 15px 0;
      font-size: 14px;
      color: inherit;
      text-decoration: none;

      @include media_desktop {
        display: inline-block;
        border-bottom: none;
        margin: 0 10px;
        padding: 0;
        text-decoration: underline;
      }
    }
    &:hover {
      a {
        opacity: .8;
        text-decoration: none;
      }

    }

  }
}

/*
フッタータイトル

フッタープロジェクトで使用するタイトル用のコンポーネントです。

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerTitle

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.2
*/
.ec-footerTitle{
  text-align: center;
  border-bottom: 1px solid;
  padding-bottom: 10px;
  @include media_desktop {
    text-align: left;
  }

  & &__logo{
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    @include reset_link();

    a{
      display: inline-block;
      font-size: 22px;
      color: inherit;
      width: 300px;
      @include media_desktop {
        font-size: 24px;
      }

    }

    &:hover {
      a {
        opacity: .8;
        text-decoration: none;
      }
    }
  }
  & &__copyright{
    font-size: 10px;

    @include media_desktop {
      font-size: 12px;
    }
  }
}
