@import "../mixins/projects";
@import "../mixins/variables";
@import "../mixins/media";

.product-slider-area {
}


.product-slider {
  position: relative;

  .slick-arrow {
    width: 30px;
    height: 30px;
    text-align: center;
    position: absolute;
    top: -2em;

    i {
      vertical-align: bottom;
    }
  }
  .slick-arrow--prev {
    right: 50px;

  }

  .slick-arrow--next {
    right: 10px;
  }

  &__title {
    border-top: 1px solid #E8E8E8;
    font-size: 2rem;
    padding: 10px 0;
  }

  dl {
    padding-top: 10px;
  }
}