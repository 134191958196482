@import "../mixins/media";
@import "../mixins/clearfix";

/*
トピック（アイテム2列）

トップページで使用されているトピックのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.5.1
*/

.ec-topicRole {
  padding-top: 40px;

  .ec-role {
    > a {
      margin-bottom: 16px;
      display: block;
    }
  }
  & &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;

  }
  & &__listItem {
    margin-bottom: 20px;
    width: 100%;
    height: auto;
    padding: 8px;

    @include media_desktop {
      width: calc(100% / 2);

    }

  }
  & &__listItemTitle {
    margin-top: .5em;
    font-size: 14px;
    color: black;

    @include media_desktop {
      margin-top: 1em;
    }
  }

}
