@import "../mixins/media";
@import "../mixins/clearfix";

/*
カテゴリ（アイテム4列 スマホの時は2列）

トップページで使用されているアイテムリストのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.6.1
*/

.ec-newItemRole {
  padding: 40px 0;

  @include media_desktop {
    padding: 60px 0;
  }

  & &__list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    .slick-track {
      height: 100%;
    }
    .slick-list {
      padding-bottom: 5px;
    }
  }
  &__listItem {
    margin-bottom: 4%;
    width: 50%;
    padding: 0 10px;

    &:not(:first-child){
      a {
        color: black;
      }
    }

    @include media_desktop {
      margin-bottom: 15px;
      width: 25%;

    }

    .slick-slider & {
      padding: 0 10px;
      margin: 0;

    }
  }
  & &__listItemHeading {
    margin-top: calc(45% - 20px);
  }
  & &__listItemTitle {
    font-size: 14px;

  }

  & &__listItemPrice {
    font-size: 12px;
    margin-top: 8px;
  }

  &__more {

    @include media_desktop {
      text-align: right;
    }

    a {
      color: #907A9F;
      transition: $transition-default;

      @include media_desktop {
        &:hover {
          text-decoration: none;
          opacity: $opacity-default;
        }
      }

      &:before {
        display: inline-block;
        content: "";
        width: 0;
        height: 0;
        margin-top: -5px;
        border: 5px solid transparent;
        border-left: 5px solid #907A9F;
      }
    }
  }
}
